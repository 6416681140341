import React from "react";
import { Link } from "gatsby";
import { uniqueId } from "lodash";
import Jumbotron from "../Jumbotron/Jumbotron";
import Spacer from "../Spacer/Spacer";
import IsrButton from "../IsrButton/IsrButton";
import { useOtherWaysToHelpHeadingSection } from "../../hooks/useOtherWaysToHelp/useOtherWaysToHelpHeadingSection";
import { useOtherWaysToHelpTopContent } from "../../hooks/useOtherWaysToHelp/useOtherWaysToHelpTopContent";
import { useOtherWaysToHelpWayToHelp } from "../../hooks/useOtherWaysToHelp/useOtherWaysToHelpWayToHelp";
import "./OtherWaysToHelpSection.scss";

const OtherWaysToHelpSection = () => {
  const { heading, backroundImage } = useOtherWaysToHelpHeadingSection();
  const { otherWaysToHelpTopContent } = useOtherWaysToHelpTopContent();
  const { otherWaysToHelpWayToHelp } = useOtherWaysToHelpWayToHelp();

  return (
    <section className="otherWaysToHelpSection">
      <Jumbotron media={backroundImage} title={heading} />
      <div className="container">
        {otherWaysToHelpTopContent && (
          <>
            <Spacer mobileSize={30} size={70} />
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: otherWaysToHelpTopContent,
              }}
            />
          </>
        )}
        <Spacer mobileSize={30} size={70} />
        <hr size="3" />
        <div className="ways-to-help">
          {otherWaysToHelpWayToHelp &&
            otherWaysToHelpWayToHelp.map((wayToHelp) => {
              const {
                title,
                content,
                buttonLinkUrl: { title: title2, url },
              } = wayToHelp;
              return (
                <div key={uniqueId("wayToHelp")} className="aWayToHelp">
                  <div className="generalContainer">
                    {title && <h3>{title}</h3>}
                    <div className="content-wrapper">
                      {content && (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        />
                      )}
                      <IsrButton type="button" variant="primary" size="md">
                        <Link to={url}>{title2}</Link>
                      </IsrButton>
                    </div>
                  </div>
                  <Spacer mobileSize={25} size={25} />
                  <hr size="3" className="divider grey" />
                  <Spacer mobileSize={25} size={25} />
                </div>
              );
            })}
        </div>

        <Spacer mobileSize={50} size={100} />
      </div>
    </section>
  );
};

export default OtherWaysToHelpSection;
