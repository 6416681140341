import { useStaticQuery, graphql } from "gatsby";
export const useOtherWaysToHelpTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Other Ways To Help" } }) {
        otherWaysToHelp {
          otherWaysToHelpTopContent
        }
      }
    }
  `);
  return data.wpPage.otherWaysToHelp;
};
