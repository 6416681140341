import { useStaticQuery, graphql } from "gatsby";
export const useOtherWaysToHelpWayToHelp = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Other Ways To Help" } }) {
        otherWaysToHelp {
          otherWaysToHelpWayToHelp {
            title
            content
            buttonLinkUrl {
              url
              title
            }
          }
        }
      }
    }
  `);
  return data.wpPage.otherWaysToHelp;
};
