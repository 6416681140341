import { useStaticQuery, graphql } from "gatsby";
export const useOtherWaysToHelpHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Other Ways To Help" } }) {
        otherWaysToHelp {
          otherWaysToHelpHeaderSection {
            heading
            backroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.otherWaysToHelp.otherWaysToHelpHeaderSection;
};
