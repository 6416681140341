import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import OtherWaysToHelpSection from "../components/OtherWaysToHelp/OtherWaysToHelpSection";
import Footer from "../components/Footer/Footer";

const OtherWaysToHelp = () => {
  return (
    <Layout>
      <Header />
      <OtherWaysToHelpSection />
      <Footer />
    </Layout>
  );
};

export default OtherWaysToHelp;
